import React from 'react';

import type { Action } from 'stream-chat';

export type AttachmentActionsProps = {
  /** A list of actions */
  actions: Action[];
  /** Unique id for action button key. Key is generated by concatenating this id with action value - {`${id}-${action.value}`} */
  id: string;
  /** The text for the form input */
  text: string;
  /** Click event handler */
  actionHandler?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    name?: string,
    value?: string,
  ) => void;
};

const UnMemoizedAttachmentActions: React.FC<AttachmentActionsProps> = (props) => {
  const { actionHandler, actions, id, text } = props;

  const handleActionClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    name?: string,
    value?: string,
  ) => {
    if (actionHandler) {
      actionHandler(event, name, value);
    }
  };

  return (
    <div className='str-chat__message-attachment-actions'>
      <div className='str-chat__message-attachment-actions-form'>
        <span key={0}>{text}</span>
        {actions.map((action) => (
          <button
            className={`str-chat__message-attachment-actions-button str-chat__message-attachment-actions-button--${action.style}`}
            data-testid={`${action.name}`}
            data-value={action.value}
            key={`${id}-${action.value}`}
            onClick={(event) => handleActionClick(event, action.name, action.value)}
          >
            {action.text}
          </button>
        ))}
      </div>
    </div>
  );
};

/**
 * AttachmentActions - renders the attachment action, which are the actions you can take on an attachment.
 * @example ./AttachmentActions.md
 */
export const AttachmentActions = React.memo(
  UnMemoizedAttachmentActions,
) as typeof UnMemoizedAttachmentActions;
